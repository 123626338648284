<template>
  <div>
    <vm-table ref="vmTable" :filter.sync="search" url="paramTypes">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(0)">
          {{ $t("common.add") }}
        </el-button>
      </template>
      <el-table-column prop="no" label="模块编号" width="100" align="center"></el-table-column>
      <el-table-column prop="name" width="" label="模块名称"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.editPage.open(scope.row.id)">{{ $t("common.edit") }}</el-button>
          <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">{{ $t("common.delete")
            }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <params-module-edit ref="editPage" @save-success="getList(-1)"></params-module-edit>
  </div>
</template>
<script>
import ParamsModuleEdit from "./ParamsModuleEdit.vue";

export default {
  components: { ParamsModuleEdit },
  data() {
    return {
      search: {
        filter: "",
      },
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },

    handleDelete(row) {
      this.$confirm(this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$http.delete("paramTypes/" + row.id).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
  },
};
</script>