<template>
  <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible"
    @close="$refs.formValidate.resetFields()">
    <el-form v-loading="contentLoading" ref="formValidate" label-width="80px" :model="paramType" :rules="ruleValidate">
      <el-form-item label="模块编号" prop="no">
        <el-input v-model="paramType.no" :placeholder="$t('common.pleaseEnter')" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item label="模块名称" prop="name">
        <el-input v-model="paramType.name" :placeholder="$t('common.pleaseEnter')" auto-complete="off"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleSubmit">{{
        $t("common.save") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      contentLoading: false,
      dialogVisible: false,
      submitLoading: false,
      paramType: {
        id: 0,
        name: "",
        no: "",
      },
      ruleValidate: {
        name: [
          { required: true, message: "模块名称不能为空", trigger: "blur" },
        ],
        no: [
          { required: true, message: "模块编号不能为空", trigger: "blur" },
        ],
      },
    };
  },

  computed: {
    title() {
      return this.paramType.id === 0 ? this.$t("common.add") : this.$t("common.edit");
    },
  },

  methods: {
    open(id) {
      this.dialogVisible = true;
      this.paramType.id = id;
      if (id) {
        this.getData();
      }
    },

    getData() {
      this.contentLoading = true;
      this.$http.get("paramTypes/" + this.paramType.id).then(res => {
        this.paramType = res.data;
        this.contentLoading = false;
      }).catch((error) => {
        this.contentLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },

    handleSubmit() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          this.$http.save("paramTypes", this.paramType).then(() => {
            this.submitLoading = false;
            this.dialogVisible = false;
            this.$emit("save-success");
            this.$message.success(this.$t("common.tip.saveSuccess"));
          }).catch(error => {
            this.submitLoading = false;
            this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
          });
        }
      });
    },
  },
};
</script>